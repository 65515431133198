import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import get from "lodash/get"

import Seo from "../components/Seo"
import Breadcrumbs from "../components/Breadcrumbs"
import ProductHero from "../components/Hero/ProductHero"
import RichText from "../components/RichText"
import Cards from "../components/Cards/Cards"
import BlockList from "../components/PageLayout/BlockList"

import { getPlainText, getPrettyURL } from "../utils"
import { ProductModel } from "../models/pages"
import { HeroSlide, SeoDataModel } from "../models/common"
import { Config } from "../models/config"
import useDictionary from "../hooks/useDictionary"

import { fetchLivePreviewData, contentstackLivePreviewWrapper } from "../live-preview"

const defaultProps = {}

export const ProductPage: React.FC<ProductModel> = props => {
  // page data
  const _page = get(props, "data.page") as any as ProductModel;
  _page.pageContext = props.pageContext;

  const _subscribe = get(props, "data.subscribe") as any as Config

  const [page, setPage] = useState(_page);
  const [subscribe, setSubscribe] = useState(_subscribe);
  
  useEffect(() => {
    contentstackLivePreviewWrapper(fetchLivePreviewData(_page, setPage, props.pageContext));
    contentstackLivePreviewWrapper(fetchLivePreviewData(_subscribe, setSubscribe));
  }, []);

  // console.log("ProductPage: ", page)
  page.pageContext = props.pageContext

  // page heading
  const heroHeading = getPlainText(page.hero.heading)
  const navLabel = getPlainText(page.nav_label)

  // SEO Fields
  const seoData: SeoDataModel = {
    metaTitle: page.seo?.title || heroHeading,
    metaDescription:
      page.seo?.description || getPlainText(page.listing?.excerpt),
    metaLink: page.seo?.canonical_link,
    metaKeywords: page.seo?.keywords,
    metaImage: page.seo?.share_image,
    slug: `/${props.pageContext.language}/${getPrettyURL(page)}`,
    locale: props.pageContext.locale,
    pageContext: props.pageContext,
  }

  const heroSlide: HeroSlide[] = page.hero.hero_image
    ? [{ image: page.hero.hero_image }]
    : []

  // combine hero and additional images but remove duplicates (based on ID)
  const slideImages = [...heroSlide, ...(page.additional_images || [])].filter(
    (value, index, self) =>
      value.image
        ? index === self.findIndex(x => x.image?.id === value.image?.id)
        : true
  )

  return (
    <div className="productPage">
      <Seo {...seoData} />

      {/* Breadcrumbs */}
      {!page.hero.hideBreadcrumbs && (
        <Breadcrumbs
          breadcrumbs={page.url_structure}
          currentPage={navLabel || heroHeading}
          pageContext={props.pageContext}
        />
      )}

      {/* Hero Images */}
      <ProductHero slides={slideImages} />

      {/* Content */}
      <section>
        <div className="grid-container">
          <div className="grid-x grid-margin-x">
            <div className="cell">
              <div className="page__body">
                <h2>{heroHeading}</h2>
                <RichText
                  content={page.content}
                  cssClass="content"
                  pageContext={props.pageContext}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Flavours */}
      <Cards
        heading={useDictionary("Flavours", props.pageContext.locale)}
        cards={page.flavours}
        pageContext={props.pageContext}
      />

      {/* Page Blocks */}
      {subscribe && (
        <BlockList
          items={subscribe?.blocks || []}
          pageContext={props.pageContext}
        />
      )}
    </div>
  )
}

ProductPage.defaultProps = defaultProps

export default ProductPage

/****
	Data
****/
export const query = graphql`
  query ProductPageByID($pageId: String!, $locale: String) {
    page: contentstackProduct(id: { eq: $pageId }) {
      __typename
      uid
      ...ProductFields
      hero {
        hide_breadcrumbs
      }
      additional_images {
        image {
          ...CmsImageFields
          gatsbyImageData(quality: 95)
        }
        image_focus
        video_url
        video_date
      }
      content
      flavours {
        ...ProductFlavourFields
      }
      seo {
        title
        description
        canonical_link
        keywords
        share_image {
          ...CmsImageFields
          gatsbyImageData(quality: 95)
        }
      }
    }

    subscribe: contentstackConfig(
      code_id: { eq: "subscribeBanner" }
      publish_details: { locale: { eq: $locale } }
    ) {
      ...ConfigFields
    }
  }

  fragment ProductFields on Contentstack_product {
    id
    url
    url_structure {
      ...UrlStructureFields
    }
    hero {
      heading
      image_focus
      hero_image {
        ...HeroImageFields
      }
      listing_img: hero_image {
        ...CmsImageFields
        gatsbyImageData(width: 590, quality: 95)
      }
    }
    logo {
      ...CmsImageFields
      gatsbyImageData(quality: 95)
    }
    listing {
      excerpt
      listing_img: listing_image {
        ...CmsImageFields
        gatsbyImageData(width: 590, quality: 95)
      }
      darken_image
      listing_color
      listing_width
      promo_size
    }
    internal {
      type
    }
    publish_details {
      locale
    }
  }
`
